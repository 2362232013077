<template>
     <div class="body">
          <div class="container">
               <div class="section-header-4">Solutions</div>
               <p class="x-large">
                    Our services are integrated with strategic adaptability, analytical design and factored to empower your business operations. We
                    reserve our rich experience of 25+ years to provide services that help you overcome foundational challenges of setting up a new
                    business or upgrading an existing one using the latest technology. Our support services are designed to accommodate changes in
                    business requirements and manage emerging needs of your customers.
               </p>
               <div class="services">
                    <div class="columns  ">
                         <div class="column is-half">
                              <div class="service-item">
                                   <router-link :to="{ name: 'AIOT' }">
                                        <div class="columns">
                                             <div class="column is-2 ">
                                                  <img src="@/assets/images/services/icons/aiot.png" alt="" class="is-hidden-touch" />
                                                  <img src="@/assets/images/services/icons/aiot3.png" class="is-hidden-desktop" alt="" />
                                             </div>
                                             <div class="column is-10 ">
                                                  <div class="section-title-2">Artificial Intelligence Of Things (AIoT)</div>
                                                  <p>
                                                       The IT landscape is being revolutionised by big data, IoT, IIoT, AI, cloud, automation and
                                                       machine learning. Enterprise business models are adapting to these changes with a better
                                                       understanding of these technologies, agile design principles, strategic technological and
                                                       infrastructural approaches.
                                                  </p>
                                             </div>
                                        </div>
                                   </router-link>
                              </div>
                         </div>
                         <div class="column is-half">
                              <div class="service-item">
                                   <router-link :to="{ name: 'CC' }">
                                        <div class="columns">
                                             <div class="column is-2 ">
                                                  <img src="@/assets/images/services/icons/cc.png" alt="" class="is-hidden-touch" />
                                                  <img src="@/assets/images/services/icons/cc3.png" alt="" class="is-hidden-desktop" />
                                             </div>
                                             <div class="column is-10 ">
                                                  <div class="section-title-2">Cloud Computing Services</div>
                                                  <p>
                                                       CTL comes in as your committed partner for cloud computing services when your business demands
                                                       are growing and your  IT capacity for innovation is not able to keep up.
                                                  </p>
                                             </div>
                                        </div>
                                   </router-link>
                              </div>
                         </div>
                    </div>
                    <div class="columns ">
                         <div class="column is-half">
                              <div class="service-item">
                                   <router-link :to="{ name: 'ITES' }">
                                        <div class="columns">
                                             <div class="column is-2 ">
                                                  <img src="@/assets/images/services/icons/ites.png" alt="" class="is-hidden-touch" />
                                                  <img src="@/assets/images/services/icons/ites3.png" alt="" class="is-hidden-desktop" />
                                             </div>
                                             <div class="column is-10 ">
                                                  <div class="section-title-2">ITeS And Networking Solutions</div>
                                                  <p>
                                                       An efficient ITeS system is the bedrock for growth intensive enterprises. CTL excels with its
                                                       vast experience in ITeS and IBMS networking, enabling it to design and develop vendor-agnostic
                                                       solutions.
                                                  </p>
                                             </div>
                                        </div>
                                   </router-link>
                              </div>
                         </div>
                         <div class="column is-half">
                              <div class="service-item">
                                   <router-link :to="{ name: 'IBMS' }">
                                        <div class="columns">
                                             <div class="column is-2 ">
                                                  <img src="@/assets/images/services/icons/ibms.png" alt="" class="is-hidden-touch" />
                                                  <img src="@/assets/images/services/icons/ibms3.png" alt="" class="is-hidden-desktop" />
                                             </div>
                                             <div class="column is-10 ">
                                                  <div class="section-title-2">Integrated Building Management System</div>
                                                  <p>
                                                       CTL designs IBMS solutions for corporates, commercial buildings, hotels, hospitals, industries,
                                                       residences, institutes, etc. These systems are commissioned to give economy of operations and
                                                       to achieve maximum utilisation of resources
                                                  </p>
                                             </div>
                                        </div>
                                   </router-link>
                              </div>
                         </div>
                    </div>

                    <div class="columns ">
                         <div class="column is-half">
                              <div class="service-item">
                                   <router-link :to="{ name: 'ESS' }">
                                        <div class="columns">
                                             <div class="column is-2 ">
                                                  <img src="@/assets/images/services/icons/ess.png" alt="" class="is-hidden-touch" />
                                                  <img src="@/assets/images/services/icons/ess3.png" alt="" class="is-hidden-desktop" />
                                             </div>
                                             <div class="column is-10 ">
                                                  <div class="section-title-2">Electronic Security Solutions</div>
                                                  <p>
                                                       As a world class provider of integrated security solutions, CTL ESS services offers total
                                                       electronic security solutions bringing the latest, reliable and proven technologies
                                                  </p>
                                             </div>
                                        </div>
                                   </router-link>
                              </div>
                         </div>
                         <div class="column is-half">
                              <div class="service-item">
                                   <router-link :to="{ name: 'AVS' }">
                                        <div class="columns">
                                             <div class="column is-2 ">
                                                  <img src="@/assets/images/services/icons/avs.png" alt="" class="is-hidden-touch" />
                                                  <img src="@/assets/images/services/icons/avs3.png" alt="" class="is-hidden-desktop" />
                                             </div>
                                             <div class="column is-10 ">
                                                  <div class="section-title-2">Audio Visual Systems</div>
                                                  <p>
                                                       CTL  has extensive experience with customised audio visual systems for commercial, retail,
                                                       hospitality and corporate environments.
                                                  </p>
                                             </div>
                                        </div>
                                   </router-link>
                              </div>
                         </div>
                    </div>
                    <div class="columns ">
                         <div class="column is-half">
                              <div class="service-item">
                                   <a href="https://ctldatacenter.web.app/" target="_blank">
                                        <div class="columns">
                                             <div class="column is-2 ">
                                                  <img src="@/assets/images/services/icons/dcs.png" alt="" class="is-hidden-touch" />
                                                  <img src="@/assets/images/services/icons/dcs3.png" alt="" class="is-hidden-desktop" />
                                             </div>
                                             <div class="column is-10 ">
                                                  <div class="section-title-2">Data Centre Solutions</div>
                                                  <p>
                                                       Since 1994, CTL has been at the forefront of building compliant on premise Data Centers. 
                                                       Customised to suit your company’s requirements and accommodate your future growth, the CTL Data
                                                       Centre solutions is a comprehensive formula to safeguard your business.
                                                  </p>
                                             </div>
                                        </div>
                                   </a>
                              </div>
                         </div>
                         <div class="column is-half">
                              <div class="service-item">
                                   <router-link :to="{ name: 'DAS' }">
                                        <div class="columns">
                                             <div class="column is-2 ">
                                                  <img src="@/assets/images/services/icons/das.png" alt="" class="is-hidden-touch" />
                                                  <img src="@/assets/images/services/icons/das3.png" alt="" class="is-hidden-desktop" />
                                             </div>
                                             <div class="column is-10 ">
                                                  <div class="section-title-2">Distributed Antenna Systems</div>
                                                  <p>
                                                       CTLs Distributed Antenna System (DAS) design meets cabling innovation with operator-grade,
                                                       enterprise-ready solutions. Our DAS portfolio of in-building wireless infrastructure solutions
                                                       offers built-in RF intelligence and flexible architectures to fit every facility, large or
                                                       small.
                                                  </p>
                                             </div>
                                        </div>
                                   </router-link>
                              </div>
                         </div>
                    </div>
               </div>

               <div class="bottomtext ">
                    <p class="">
                         Our services are validated with International Certifications and Partnerships.
                         <router-link :to="{ name: 'CERTIFICATION' }">Know More</router-link>
                    </p>
               </div>
          </div>
     </div>
</template>

<script>
     export default {
          methods: {},
     };
</script>

<style lang="scss" scoped>
     .body {
          padding-top: 45px;
          padding-bottom: 100px;

          @media (max-width: 1023px) {
               padding-top: 20px;
          }
     }
     .section-header-4 {
          margin-bottom: 30px;
     }
     p.x-large {
          max-width: 1200px;
     }
     .bottomtext {
          text-align: center;
          p {
               display: inline-block;
               text-align: left;
               font-size: 24px;
               font-weight: 300;
               margin: 40px auto 20px;

               a {
                    font-size: 17px;
               }
          }
     }

     .details {
          .column.is-half {
               padding-right: 80px;
          }
     }

     .services {
          margin-top: 150px;
          margin-bottom: 40px;

          @media (max-width: 1023px) {
               margin-top: 100px;
               margin-bottom: 10px;
          }

          .service-item {
               .section-title-2 {
                    @media (max-width: 1023px) {
                    }
               }
               a {
                    color: inherit;
               }
               transition: transform 0.2s;

               p {
                    padding-bottom: 40px;
                    font-size: 17px;

                    @media (max-width: 1023px) {
                         padding-bottom: 70px;
                    }
               }
          }
          .service-item:hover {
               transform: scale(1.05);
          }
     }

     .iconsvg {
          width: 100% !important;
          height: auto;
          max-width: 55px;
          display: block;
          margin: auto;
     }
</style>
